<!-- eslint-disable -->
<template>
  <DocPage :titles="titles" title="Cookie Policy">
    <div>
      <h1 id="What are cookies?">What are cookies?</h1>
      <br />
      Our website, like many others, uses cookies in order to work properly.
      Cookies are small text files that are downloaded onto your device when you
      access our website. They allow us to recognize your device and store some
      information about your preferences or past actions on our website,
      ultimately allowing us to improve your experience. This cookie policy
      describes the kinds of information that we gather, how we use the
      information, and why we need to store these cookies. We will also explain
      how you can prevent these cookies from being stored; however, this may
      downgrade or break certain elements of the site's functionality.<br /><br />
      <br />
      <h2 id="How we use cookies">How we use cookies</h2>
      <br /><br />
      We use cookies to understand how users interact with our services and thus
      how we can improve them. Details of this usage are described below.
      Unfortunately, in most cases there are no industry standard options for
      disabling cookies without completely disabling the functionality and
      features they add to the site. It is recommended that you accept our usage
      of cookies if you are not sure whether you need them or not, in case they
      are neccessary for a service that you use.<br /><br />
      <br />
      <h2 id="The types of cookies we use">The types of cookies we use</h2>
      <br /><br />
      If you create an account at our site, then we will use cookies for the
      management of the sign-up process and general administration. These
      cookies will usually be deleted when you log out; however, in some cases
      they may remain afterwards to remember your site preferences when logged
      out.<br /><br />
      We use cookies when you are logged in, so that we can remember this fact.
      This prevents you from having to log in every single time you visit a new
      page. These cookies are typically removed or cleared when you log out to
      ensure that you can only access restricted features and areas when logged
      in.<br /><br />
      When you submit data through a form such as a contact page or comment box,
      cookies may be set to remember your user details for future
      correspondence.In order to provide you with a great experience on this
      site, we provide the functionality to set your preferences for how this
      site runs when you use it.<br /><br />
      In order to remember your preferences, we need to set cookies so that this
      information can be called whenever you interact with a page that is
      affected by your preferences.<br /><br />
      Some of the cookies we use are persistent, which means that they remain
      stored on your device for one year.<br /><br />
      As we sell services, it is important for us to understand statistics on
      how many of the visitors to our site actually make a purchase, and thus
      cookies will track this kind of data. This is important to you as it means
      that we can accurately make business predictions that allow us to monitor
      our advertising and product costs to ensure the best possible price.<br /><br />
      The behavioural advertising cookies used by this site are designed to
      ensure that we provide you with the most relevant adverts where possible
      by anonymously tracking your interests and presenting similar things that
      you may like.<br /><br />
      In some cases, we may provide you with custom content based on what you
      tell us about yourself, either directly or indirectly if you link a social
      media account. These types of cookies simply allow us to provide you with
      content that we feel may be of interest to you.<br /><br />
      <br />
      <h2 id="Use of third-party cookies">Use of third-party cookies</h2>
      <br /><br />
      In some special cases we also use cookies provided by trusted third
      parties. The following section specifies which third-party cookies you
      might encounter at this site and reasons why we use them.<br /><br />
      Third-party analytics are used to track and measure usage of this site so
      that we can continue to produce engaging content. These cookies may track
      things such as how long you spend on the site or pages you visit, which
      helps us to understand how we can improve the site for you.<br /><br />
      From time to time we test new features and make subtle changes to the way
      the site is delivered. While we are still testing new features, these
      cookies may be used to ensure that you receive a consistent experience on
      our site. That way, we will be able to understand which optimizations our
      users appreciate most.<br /><br />
      Several partners advertise on our behalf and affiliate-tracking cookies
      simply allow us to see if our customers have come to the site through one
      of our partner sites. This lets us credit them appropriately, and also
      allows you to enjoy any bonuses they might be offering for use of their
      service.<br /><br />
      <br />
      <h2 id="How to disable cookies">
        Sharing and Cross-Border Transfer of Information
      </h2>
      <br /><br />
      A lot of web browsers allow control of cookies through the settings. You
      can prevent the setting of cookies by adjusting the browser settings (see
      your browser's Help section for the instructions).Find out how to manage
      cookies on popular browsers:<br />
      ▪ Google Chrome<br />
      ▪ Microsoft Edge<br />
      ▪ Mozilla Firefox<br />
      ▪ Microsoft Internet Explorer<br />
      ▪ Opera<br />
      ▪ Apple Safari<br /><br />
      Be aware that disabling cookies will affect the functionality of our
      website. Disabling cookies will usually affect the functionality and
      certain features of sites.<br /><br />
      <br />
      <h2 id="More information">More information</h2>
      <br /><br />
      We hope this article has clarified things for you. As previously
      mentioned, it's best to leave cookies enabled just in case they are needed
      while you use our site. However, if you'd still like more information,
      feel free to contact us
      <a href="mailto:support@inst.money"> support@inst.money </a><br /><br />
      <br />
    </div>
  </DocPage>
</template>

<script>
import DocPage from '@/views/DocPage.vue';
/**
 * @author yuanzeyu
 * @date 2020-02-11
 * @desc
 */
export default {
  name: 'Privacy',
  components: { DocPage },
  data() {
    return {
      titles: [
        'What are cookies?',
        'How we use cookies',
        'The types of cookies we use',
        'Use of third-party cookies',
        'How to disable cookies',
        'More information',
      ],
    };
  },
};
</script>
